/* eslint-disable import/prefer-default-export */
import clsx from "clsx";
import { ForwardedRef, forwardRef } from "react";

import {
  atlasCircleChecked,
  atlasRingWarning,
  atlasSmallClose,
} from "../../icons";
import { useOptionalRef } from "../__utils/__deprecated";
import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps } from "../__utils/react";
import { Button } from "../button";
import { Icon } from "../icon";
import { Link } from "../link";
import type { AtlasToastProps } from "./types";

// config
// ------

const COMPONENT_NAME = "Toast";
const DEFAULT_PROPS = createDefaultProps<AtlasToastProps>()({
  variant: "default",
  isDismissible: true,
} as const);

const SUCCESS_ICON = atlasCircleChecked;
const ERROR_ICON = atlasRingWarning;
const DISMISS_ICON = atlasSmallClose;

const { ROOT, el } = createComponentUtils(COMPONENT_NAME);

// helpers
// -------

function getIcon(variant: AtlasToastProps["variant"]) {
  switch (variant) {
    case "success":
      return SUCCESS_ICON;
    case "error":
      return ERROR_ICON;
    default:
      throw new Error("Unknown variant");
  }
}

// components
// ----------

/**
 * A notification that displays relevant information. It can contain up to two actions and
 * can be dismissed.
 */
export const Toast = forwardRef<HTMLDivElement, AtlasToastProps>(
  (
    {
      variant = DEFAULT_PROPS.variant,
      isDismissible = DEFAULT_PROPS.isDismissible,
      actions,
      description,
      title,
      onDismiss,
    }: AtlasToastProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    const domRef = useOptionalRef(ref);

    // rendering
    // ---------

    const hasIcon = variant === "success" || variant === "error";
    const isInline = actions?.length === 1;

    return (
      <div
        ref={domRef}
        className={clsx(`${ROOT} variant-${variant}`, {
          "has-description": description,
          "has-icon": hasIcon,
          "has-actions": actions?.length,
          "has-single-action": actions?.length === 1,
          "is-inline": isInline,
        })}
      >
        {hasIcon && <Icon content={getIcon(variant)} className={el`icon`} />}
        <div className={el`content`}>
          <p className={el`title`}>{title}</p>
          {description && <p className={el`description`}>{description}</p>}
          {actions && (
            <div className={el`actions`}>
              {actions.map(({ label, ...linkProps }) => (
                <Link key={label} {...linkProps}>
                  {label}
                </Link>
              ))}
            </div>
          )}
        </div>
        {isDismissible && (
          <Button
            onClick={() => onDismiss?.()}
            className={el`dismiss-button`}
            negativeMargin="right"
            isGhost
            icon={DISMISS_ICON}
            size="xs"
            iconSize="small"
          />
        )}
      </div>
    );
  }
);
