import { ReactElement } from "react";

import Tooltip from "./Tooltip";
import type { AtlasTooltipProps } from "./types";

/**
 * Exactly like Tooltip, but it will skip rendering a
 * tooltip if the `content` prop is not set.
 */
function OptionalTooltip(props: AtlasTooltipProps): ReactElement {
  const { content, children } = props;

  if (content) return <Tooltip {...props} />;
  return <>{children}</>;
}

export default OptionalTooltip;
