export function getAvatarInitialsFromName(name: string) {
  const matchResult = name?.matchAll(/(\S)\S*/g);
  const matches = [...matchResult].map((r) => r[1]);
  return matches ? `${matches[0]}${matches[1] ?? ""}` : undefined;
}

// avatar color by initials

/** Computes a number from the characters of some initials. */
function getInitialsMagicNumber(initials: string) {
  const numbers = initials
    .toLowerCase()
    .substring(0, 2)
    .split("")
    .map((char) => char.charCodeAt(0));
  // this will make permutations (e.g. "DG" and "GD") have different colors without
  // significantly impacting the probability distribution.
  const spice = numbers[0] < numbers[1] ? 0 : 1;
  return numbers.reduce((acc, n) => acc + n) + spice;
}

const COLORS = ["purple", "orange", "green", "yellow", "blue"] as const;

/** Maps initials to a color in a deterministic way. */
export function getAvatarColorFromInitials(initials: string) {
  const magicNumber = getInitialsMagicNumber(initials);
  const colorIndex = magicNumber % COLORS.length;
  return COLORS[colorIndex];
}
