/* eslint-disable import/prefer-default-export */

import { Ref, RefObject, useRef } from "react";

/**
 * Returns a fallback ref if no ref or a callback ref is passed.
 * Otherwise, it returns the original ref.
 * @deprecated Create an additional ref and merge it instead.
 */
export function useOptionalRef<T>(ref?: Ref<T> | null): RefObject<T> {
  const fallbackRef = useRef<T>(null);
  if (ref && "instance" in ref) return fallbackRef;
  return (ref as RefObject<T>) ?? fallbackRef;
}
