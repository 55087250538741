/* eslint-disable import/prefer-default-export */
import { SnackbarKey, useSnackbar } from "notistack";
import { useCallback } from "react";

import { Toast } from "./Toast";
import { AtlasToastAction, AtlasToastProps } from "./types";

type ToastMessage = string;
type ToastOptions = {
  variant?: AtlasToastProps["variant"];
  description?: AtlasToastProps["description"];
  isDismissible?: AtlasToastProps["isDismissible"];
  actions?: (AtlasToastAction & { isDismissAction?: boolean })[];
};
type ToastKey = SnackbarKey;
type UseToastReturn = {
  sendToast: (message: ToastMessage, options?: ToastOptions) => ToastKey;
  dismissToast: (key?: ToastKey) => void;
};

export function useToast(): UseToastReturn {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const sendToast = useCallback(
    (message: ToastMessage, options: ToastOptions = {}) => {
      const getToastProps = (key: ToastKey): AtlasToastProps => ({
        title: message,
        description: options.description,
        variant: options.variant,
        isDismissible: options.isDismissible,
        // @ts-expect-error - Toast is legacy.
        actions: options?.actions?.map((action) => {
          if (action.isDismissAction)
            return {
              ...action,
              as: "button",
              onClick: (event) => {
                // @ts-expect-error - Toast is legacy.
                action?.onClick?.(event);
                closeSnackbar(key);
              },
            };
          return action;
        }),
      });
      return enqueueSnackbar(message, {
        content: (key) => (
          <Toast {...getToastProps(key)} onDismiss={() => closeSnackbar(key)} />
        ),
      });
    },
    [closeSnackbar, enqueueSnackbar]
  );

  return { sendToast, dismissToast: closeSnackbar };
}
