import _ from "lodash";
import npmPluralize from "pluralize";

export const possessive = (str: string, fallback = ""): string => {
  if (!str) return fallback;
  return str.endsWith("s") ? `${str}'` : `${str}'s`;
};

type SplitName = {
  firstName: string;
  lastName: string;
};

/*
 * Naive Name splitting to be used.
 */
export const splitName = (name: string | null | undefined): SplitName => {
  const [firstName, ...lastNameParts] = _.split(name || "", " ");
  const lastName = _.join(lastNameParts, " ");

  return {
    firstName,
    lastName,
  };
};

/*
 * Join first and last names, and trim whitespace
 */
export const joinNames = (
  firstName: string | null | undefined,
  lastName: string | null | undefined
): string => `${firstName ?? ""} ${lastName ?? ""}`.trim();

// Stolen straight from:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#Escaping
export const escapeRegExp = (toBeEscaped: string): string =>
  toBeEscaped.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string

/*
 * Join the elements in an javascript array,
 * but let the last separator be different eg: `and` / `or`
 * Stackoverflow link: http://stackoverflow.com/questions/15069587/is-there-a-way-to-join-the-elements-in-an-js-array-but-let-the-last-separator-b
 * Credit: Chris Barr - http://stackoverflow.com/users/79677/chris-barr
 */
export const joinWithFinalSeparator = (
  values: string[] = [],
  separator = ", ",
  finalSeparator = " & "
): string => {
  let outStr = "";
  if (values?.length === 1) {
    [outStr] = values;
  } else if (values.length === 2) {
    // joins all with only final separator
    outStr = _.join(values, finalSeparator);
  } else if (values.length > 2) {
    // joins all with separator and using final separator
    outStr = `${values
      .slice(0, -1)
      .join(separator)}${finalSeparator}${values.slice(-1)}`;
  }
  return outStr;
};

export const pluralize = (
  word: string,
  length: number,
  inclusive = true
): string => npmPluralize(word, length, inclusive);

export const initials = (fullName?: string): string => {
  const names = splitName(fullName);
  return `${names.firstName[0]}${names.lastName[0]}`;
};

export const ellipsize = (word: string, maxCharacters: number): string => {
  if (word.length > maxCharacters) {
    return `${word.substring(0, maxCharacters)}…`;
  }
  return word;
};
