/* eslint-disable jsx-a11y/anchor-has-content */
import {
  AtlasAvatarProps,
  AtlasMenuContentItem,
  Avatar,
  Menu,
} from "@resource/atlas";
import { useLogEvent } from "analytics";
import { useAuthContext } from "auth/context";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useCallback, useMemo, useState } from "react";
import { analytics } from "react-hooks/useAnalytics";
import useExtension from "react-hooks/useExtension";
import useExtensionLink from "react-hooks/useExtensionLink";
import {
  CHROME_EXTENSION_INSTALL_PAGE,
  CHROME_EXTENSION_TUTORIAL,
  PRIVACY_POLICY_PAGE,
  TERMS_OF_SERVICE_PAGE,
} from "utils/constants/href";
import inIframe from "utils/in-iframe";
import { PermissionEnum } from "utils/permissions";

type AccountMenuProps = {
  triggerSize?: AtlasAvatarProps["size"];
  target?: "self" | "_blank";
  hideDashboard?: boolean;
};

function AccountMenu({
  triggerSize = "medium",
  target,
  hideDashboard,
}: AccountMenuProps) {
  const { installed: isExtensionInstalled } = useExtension();
  const extensionLink = useExtensionLink();
  const [loggingOut, setLoggingOut] = useState(false);
  const { onLogout } = useAuthContext();
  const router = useRouter();
  const logEvent = useLogEvent({ component: "AccountMenu" });
  const { checkRolePermissions, user } = useAuthContext();

  const isCandidateUser =
    user?.currentUserMembership?.highestRole?.id === "candidate";

  const header = useMemo(
    () => (
      <div key="account-menu-top" className="px-3 py-1.5 space-y-0.5">
        <p className="text-body-md-heavy text-dark truncate">
          {user?.fullName ?? ""}
        </p>
        <p className="text-body-md text-subtle truncate">
          {user?.primaryEmail ?? ""}
        </p>
      </div>
    ),
    [user?.fullName, user?.primaryEmail]
  );

  const hasPermission = useCallback(
    ({ key }: AtlasMenuContentItem) => {
      if (key === "organization-settings") {
        return checkRolePermissions(PermissionEnum.ORGANIZATION_WRITE);
      }

      return true;
    },
    [checkRolePermissions]
  );

  const logout = useCallback(async () => {
    analytics.track("Sidebar Sign Out Clicked");
    setLoggingOut(true);
    await onLogout();
    if (inIframe()) {
      // Logging out from within an iframe doesn't work properly, so we need to pen a new tab
      // that logs the user out. See https://community.auth0.com/t/logout-redirect-url-ignores-additional-url-query-parameters-in-custom-rule/73219
      window.open(
        `/api/auth/logout?returnTo=/login/?returnTo=${encodeURIComponent("/")}`
      );

      // Then we need to redirect the user to the login page with the returnTo query param set properly
      router.push(`/login/?returnTo=${encodeURIComponent(router.asPath)}`);
    } else {
      window.location.assign(
        `/api/auth/logout?returnTo=/login/?returnTo=${encodeURIComponent(
          router.asPath
        )}`
      );
    }
  }, [onLogout, router]);

  const items = useMemo(
    () =>
      (
        [
          ...(isCandidateUser
            ? []
            : ([
                ...(hideDashboard
                  ? []
                  : [
                      {
                        _type: "item",
                        children: "Company dashboard",
                        key: "company-dashboard",
                        onSelect: () =>
                          analytics.track(
                            "Guide Account Menu Dashboard Clicked"
                          ),
                        renderWrapper: (props) => (
                          <NextLink href="/">
                            <a {...props} target={target} />
                          </NextLink>
                        ),
                      } as AtlasMenuContentItem,
                    ]),
                {
                  _type: "item",
                  children: "Account & preferences",
                  key: "account-and-preferences",
                  onSelect: () =>
                    analytics.track(
                      "Guide Account Menu Account & Preferences Clicked"
                    ),
                  renderWrapper: (props) => (
                    <NextLink href="/account">
                      <a {...props} target={target} />
                    </NextLink>
                  ),
                },
                {
                  _type: "item",
                  children: "Organization settings",
                  key: "organization-settings",
                  onSelect: () =>
                    analytics.track(
                      "Guide Account Menu Organization Settings Clicked"
                    ),
                  renderWrapper: (props) => (
                    <NextLink href="/settings">
                      <a {...props} target={target} />
                    </NextLink>
                  ),
                },
                { _type: "separator", key: "separator-1" },
                {
                  _type: "item",
                  children: "Install Chrome extension",
                  key: "install-chrome-extension",
                  onSelect: () => {
                    logEvent("Menu Install Clicked", {
                      project: "Check and prompt Extension install",
                    });
                    if (isExtensionInstalled) {
                      window.open(CHROME_EXTENSION_INSTALL_PAGE, "_blank");
                    } else {
                      extensionLink.navigate(CHROME_EXTENSION_TUTORIAL);
                    }
                  },
                },
                { _type: "separator", key: "separator-2" },
              ] as AtlasMenuContentItem[])),
          {
            _type: "item",
            children: "Terms of service",
            key: "terms-of-service",
            onSelect: () =>
              analytics.track("Account Menu Terms Of Service Clicked"),
            renderWrapper: (props) => (
              <a
                {...props}
                href={TERMS_OF_SERVICE_PAGE}
                target="_blank"
                rel="noreferrer"
              />
            ),
          },
          {
            _type: "item",
            children: "Privacy policy",
            key: "privacy-policy",
            onSelect: () =>
              analytics.track("Account Menu Privacy Policy Clicked"),
            renderWrapper: (props) => (
              <a
                {...props}
                href={PRIVACY_POLICY_PAGE}
                target="_blank"
                rel="noreferrer"
              />
            ),
          },
          { _type: "separator", key: "separator-3" },
          {
            _type: "item",
            children: loggingOut ? "Signing out…" : "Sign out",
            key: "sign-out",
            onSelect: logout,
          },
        ] as AtlasMenuContentItem[]
      ).filter(hasPermission),
    [
      isCandidateUser,
      hasPermission,
      logEvent,
      isExtensionInstalled,
      extensionLink,
      target,
      logout,
      loggingOut,
      hideDashboard,
    ]
  );

  return (
    <Menu.Root>
      <Menu.Trigger>
        <Avatar
          as="button"
          size={triggerSize}
          name={user?.fullName}
          image={user?.imageUrl}
          disableTooltip
          aria-label="Open account menu"
        />
      </Menu.Trigger>
      <Menu.Content defaultSize="compact" header={header} items={items} />
    </Menu.Root>
  );
}

export default AccountMenu;
