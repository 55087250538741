// This file has been automatically generated.

export const atlasSmallCheck =
  "M9.946 2.522a.75.75 0 0 1 .157 1.049l-4.25 5.75a.75.75 0 0 1-1.133.084l-2.75-2.75a.75.75 0 0 1 1.06-1.06l2.134 2.134 3.733-5.05a.75.75 0 0 1 1.049-.157Z M123.45 12";

export const atlasSmallChevronLeft =
  "M8.03 2.47a.75.75 0 0 1 0 1.06L5.56 6l2.47 2.47a.75.75 0 0 1-1.06 1.06l-3-3a.75.75 0 0 1 0-1.06l3-3a.75.75 0 0 1 1.06 0Z M123.45 12";

export const atlasSmallChevronRight =
  "M3.97 9.53a.75.75 0 0 1 0-1.06L6.44 6 3.97 3.53a.75.75 0 0 1 1.06-1.06l3 3a.75.75 0 0 1 0 1.06l-3 3a.75.75 0 0 1-1.06 0Z M123.45 12";

export const atlasSmallCircleWarningColor = {
  data: (
    <>
      <g clipPath="url(#a)" fillRule="evenodd" clipRule="evenodd">
        <path d="M.5 6a5.5 5.5 0 1 1 11 0 5.5 5.5 0 0 1-11 0Z" />
        <path
          d="M6 7.2a.6.6 0 1 1 0 1.2.6.6 0 0 1 0-1.2Zm0-.6a.45.45 0 0 1-.45-.45v-2.1a.45.45 0 1 1 .9 0v2.1A.45.45 0 0 1 6 6.6Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h12v12H0z" />
        </clipPath>
      </defs>
    </>
  ),
  size: 12,
};

export const atlasSmallClose =
  "M2.22 2.22a.75.75 0 0 1 1.06 0L6 4.94l2.72-2.72a.75.75 0 0 1 1.06 1.06L7.06 6l2.72 2.72a.75.75 0 0 1-1.06 1.06L6 7.06 3.28 9.78a.75.75 0 0 1-1.06-1.06L4.94 6 2.22 3.28a.75.75 0 0 1 0-1.06Z M123.45 12";

export const atlasSmallLock =
  "M6 2.5A1.5 1.5 0 0 0 4.5 4v.5h3V4A1.5 1.5 0 0 0 6 2.5Zm3 2a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-4a1 1 0 0 1 1-1V4a3 3 0 0 1 6 0v.5Z M123.45 12";

export const atlasSmallMailOpen =
  "M6.242 1.062a.5.5 0 0 0-.484 0l-4.5 2.483A.5.5 0 0 0 1 3.983v5.276C1 10.22 1.78 11 2.741 11H9.26C10.22 11 11 10.22 11 9.259V3.983a.5.5 0 0 0-.258-.438l-4.5-2.483ZM10 4.562v-.284L6 2.071 2 4.278v.284L5.374 7.3h1.252L10 4.562ZM2 5.85v3.409c0 .41.332.741.741.741H9.26c.41 0 .741-.332.741-.741v-3.41L7.119 8.19a.5.5 0 0 1-.315.111H5.196a.5.5 0 0 1-.315-.112L2 5.85Z M123.45 12";

export const atlasSmallMinus =
  "M1 6c0-.414.24-.75.536-.75h8.928c.296 0 .536.336.536.75s-.24.75-.536.75H1.536C1.24 6.75 1 6.414 1 6Z M123.45 12";

export const atlasSmallOpenExternal =
  "M0 2.75A2.75 2.75 0 0 1 2.75 0h.5a.75.75 0 0 1 0 1.5h-.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h6.5c.69 0 1.25-.56 1.25-1.25v-.5a.75.75 0 0 1 1.5 0v.5A2.75 2.75 0 0 1 9.25 12h-6.5A2.75 2.75 0 0 1 0 9.25v-6.5ZM6.75 1.5a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V2.56L6.28 6.78a.75.75 0 0 1-1.06-1.06L9.44 1.5H6.75Z M123.45 12";

export const atlasSmallRingWarning =
  "M6 11A5 5 0 1 0 6 1a5 5 0 0 0 0 10Zm0-.833a4.167 4.167 0 1 0 0-8.334 4.167 4.167 0 0 0 0 8.334ZM6 7.11a.556.556 0 1 0 0 1.111.556.556 0 0 0 0-1.11Zm0-3.333a.417.417 0 0 0-.417.416V6.14a.417.417 0 0 0 .834 0V4.194A.417.417 0 0 0 6 3.778Z M123.45 12";

export const atlasSmallStarFilled =
  "M5.524 1.464a.5.5 0 0 1 .952 0l.871 2.682h2.82a.5.5 0 0 1 .294.904L8.18 6.708l.871 2.683a.5.5 0 0 1-.77.559L6 8.292 3.718 9.95a.5.5 0 0 1-.77-.56l.872-2.682L1.54 5.05a.5.5 0 0 1 .294-.904h2.82l.871-2.682Z M123.45 12";
