/* eslint-disable import/prefer-default-export, @typescript-eslint/no-explicit-any */
import { Button } from "ariakit";
import clsx from "clsx";
import { ReactElement, useEffect, useMemo, useState } from "react";

import {
  atlasBriefcase,
  atlasCircleCheckedColor,
  atlasCircleCrossColor,
  atlasCircleQuestionColor,
  atlasEdit2,
  atlasPerson,
} from "../../icons";
import { setTooltipConfig } from "../__utils/__deprecated";
import { createComponentUtils } from "../__utils/atlas";
import { createDefaultProps, mergeProps } from "../__utils/react";
import { Icon } from "../icon";
import OptionalTooltip from "../tooltip/OptionalTooltip";
import { useKeyboardActive } from "../utils";
import { useImageIsLoaded } from "./__utils/use-image-loading-status";
import {
  getAvatarColorFromInitials,
  getAvatarInitialsFromName,
} from "./initials";
import type {
  AtlasAvatarComponent,
  AtlasAvatarOptions,
  AtlasAvatarProps,
} from "./types";

// config
// ------

const COMPONENT_NAME = "Avatar";
const DEFAULT_PROPS = createDefaultProps<AtlasAvatarProps>()({
  variant: "user",
  size: "medium",
} as const);
const DEFAULT_ELEMENT = "div";

const USER_ICON = atlasPerson;
const ENTITY_ICON = atlasBriefcase;
const ACCEPTED_ICON = atlasCircleCheckedColor;
const DECLINED_ICON = atlasCircleCrossColor;
const WAITING_ICON = atlasCircleQuestionColor;
const EDIT_ICON = atlasEdit2;

const FALLBACK_RENDER_DELAY = 1000;

const { ROOT, el, createComponent } = createComponentUtils(COMPONENT_NAME);

// utils
// -----

// initials

function useComputedInitials(initials?: string, name?: string) {
  return useMemo(() => {
    if (initials) return initials.substring(0, 2).toUpperCase();
    return name ? getAvatarInitialsFromName(name)?.toUpperCase() : undefined;
  }, [initials, name]);
}

// status icon

function getStatusIcon(status: NonNullable<AtlasAvatarOptions["status"]>) {
  switch (status) {
    case "accepted":
      return ACCEPTED_ICON;
    case "declined":
      return DECLINED_ICON;
    case "waiting":
      return WAITING_ICON;
    default:
      throw new Error("Unknown status");
  }
}

// avatar
// ------

/**
 * An avatar that displays an image, a set of initials, or a fallback icon.
 * Can be used as a button with `as="button"` and as a link `as="a"`, while
 * remaining accessible.
 */
export const Avatar = createComponent<AtlasAvatarComponent>(
  (p0) => {
    const {
      as = DEFAULT_ELEMENT,
      variant = DEFAULT_PROPS.variant,
      size = DEFAULT_PROPS.size,
      ref,
      initials,
      showEditIcon,
      name,
      showBorder,
      image: imageUrl,
      status,
      disableTooltip,
      UNSAFE_disableFocus: disableFocus,
      ...props
    } = p0;

    const { keyboardActiveProps } = useKeyboardActive(p0);

    const computedInitials = useComputedInitials(initials, name);

    const getInitialsText = () => (
      <p className={el`initials`}>{computedInitials}</p>
    );
    const getFallbackIcon = () => (
      <Icon
        className={el`fallback-icon`}
        content={variant === "user" ? USER_ICON : ENTITY_ICON}
      />
    );

    const imageLoaded = useImageIsLoaded(imageUrl);
    const [fallbackDelayDone, setFallbackDelayDone] = useState(false);
    useEffect(() => {
      const timeout = setTimeout(
        () => setFallbackDelayDone(true),
        FALLBACK_RENDER_DELAY
      );
      return () => clearTimeout(timeout);
    }, []);

    let content: ReactElement | null = null;
    if (imageUrl)
      content = (
        <>
          <img className={el`image`} alt={name} src={imageUrl} />
          <div className={el`fallback`}>
            {computedInitials ? getInitialsText() : getFallbackIcon()}
          </div>
        </>
      );
    else if (computedInitials) content = getInitialsText();
    else content = getFallbackIcon();

    const statusIcon = status ? (
      <Icon className={el`status-icon`} content={getStatusIcon(status)} />
    ) : null;

    const initialsColor = computedInitials
      ? getAvatarColorFromInitials(computedInitials)
      : "gray";

    let color = "white";
    if (imageUrl) {
      if (imageLoaded) color = "white";
      else if (fallbackDelayDone) color = initialsColor;
      else color = "gray";
    } else color = initialsColor;

    const isInteractive = as === "button" || as === "a";
    const RootComponent = (isInteractive ? Button : "div") as any;

    return (
      <RootComponent
        ref={ref}
        as={isInteractive ? as : undefined}
        {...mergeProps(keyboardActiveProps, props)}
        className={clsx(
          `${ROOT} variant-${variant} size-${size} color-${color}`,
          { [`status-${status}`]: status },
          { isInteractive, imageLoaded, fallbackDelayDone, showBorder },
          props.className
        )}
        tabIndex={props.tabIndex ?? disableFocus ? -1 : undefined}
      >
        <OptionalTooltip
          content={disableTooltip ? undefined : name}
          isInstant
          UNSAFE_mode="slot"
        >
          <div className={el`content`}>
            {content}
            {isInteractive && (
              <div className={el`overlay`}>
                {showEditIcon && (
                  <Icon className={el`edit-icon`} content={EDIT_ICON} />
                )}
              </div>
            )}
          </div>
        </OptionalTooltip>
        {variant === "user" && statusIcon}
      </RootComponent>
    );
  },
  { metadata: { collectionType: "avatar" } }
);
setTooltipConfig(Avatar, { mode: "slot" });

// TODO: wrap the root component with the tooltip (currently not doing that because it causes trouble with anchoring when used as menu button)
